@import '~styles/colors';

.searchInputComponent {
	position: relative;
	min-width: 400px;
	i {
		color: $primaryColor;
	}
	.searchInput {
		display: flex;
		padding: 1px;
		flex-direction: row;
		align-items: center;
		width: 300px;
		background-color: $lightBlue;
		border-radius: 3px;
		cursor: text;
	}
	.searchInputField {
		flex-grow: 1;
		border: none;
		overflow: hidden;
		background-color: transparent;
		&:focus {
			border: none;
			outline: none;
		}
	}

	.decoraterIcon {
		padding-left: 0.125em;
		padding-right: 0.125em;
	}

	.rotatorContainer {
		padding-right: 0.25em;
	}
}

.searchInputDropDown {
	margin-top: -3px;
	background-color: $lightBlue;
	border: 1px solid $lightBlue;
	box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.3);
	max-height: 30em;
	width: 300px;
	cursor: pointer;
	@media screen and (max-width: 500px) {
		width: 90vw;
		max-width: 90vw;
	}
	overflow-x: hidden;
	overflow-y: auto;
	padding: 1px;
	display: flex;
	flex-direction: column;
	z-index: 1;
	> * {
		overflow: visible;
	}
	ul {
		padding: 0;
	}
	li {
		&.highlightedItem {
			.communityName {
				font-weight: bold;
			}
			i {
				color: white;
			}
		}
		list-style: none;
	}

	.searchResultList {
		margin: 0;
		padding-left: 1.25em;
	}

	.searchResultItem {
		cursor: pointer;
	}
}
