@import '~styles/safeAreaInsets';
@import '~styles/colors';
@import '~styles/sizes';

.addContentButtonContainer {
	div {
		position: fixed;
		bottom: 30px;
		right: 0;
		@media screen and (min-width: 1280px) {
			right: calc((100vw - 1280px) / 2);
		}
		margin-right: 30px;
		border-radius: 50%;
		border: 3px solid $primaryColor;
		display: flex;
		justify-items: center;
		align-items: center;
		z-index: 2;
		width: 50px;
		height: 50px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
		-webkit-transition: opacity, transform 0.5s ease;
		opacity: 0.8;
		background-color: $backgroundColor;
		@include supports-safe-area-insets {
			bottom: 0;
			margin-bottom: max(30px, $safe-area-inset-bottom);
			margin-right: calc(30px + #{$safe-area-inset-right});
		}
	}

	svg {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 50px;
		height: 50px;
		color: $primaryColor;
		transform: scale(0.7);
	}
}

.addContentButton-appear {
	opacity: 0;
}
.addContentButton-enter {
	opacity: 0;
}
.addContentButton-appear-active {
	animation-name: addContentButtonAnimation;
	animation-duration: 300ms;
	animation-timing-function: ease-out;
}
.addContentButton-enter-active {
	animation-name: addContentButtonAnimation;
	animation-duration: 300ms;
	animation-timing-function: ease-out;
}
.addContentButton-enter-done {
	&:hover {
		transform: scale(1.25, 1.25);
		box-shadow: 0.01em 0.1em 0.5em 0.2em rgba(0, 0, 0, 0.3);
		opacity: 1;
		transition: opacity, transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
		-webkit-transition: opacity, transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
}
.addContentButton-exit-active {
	animation-name: addContentButtomAnimation;
	animation-duration: 300ms;
	animation-timing-function: ease-in;
	animation-direction: reverse;
	z-index: 3;
}

@keyframes addContentButtonAnimation {
	0% {
		opacity: 0;
		transform: scale(0.2);
	}
	100% {
		opacity: 0.8;
		transform: scale(1);
	}
}

.addSubButton {
	position: absolute;
	transform: translateY(-60px);
}

.addSubButton-appear-active {
	animation-name: addSubButtonAnimation;
	animation-duration: 300ms;
	animation-timing-function: ease-out;
}
.addSubButton-enter-active {
	animation-name: addSubButtonAnimation;
	animation-duration: 300ms;
	animation-timing-function: ease-out;
}
.addSubButton-enter-done {
	&:hover {
		transform: translateY(-60px) scale(1.25, 1.25);
		box-shadow: 0.01em 0.1em 0.5em 0.2em rgba(0, 0, 0, 0.3);
		opacity: 1;
		transition: opacity, transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
		-webkit-transition: opacity, transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
}
.addSubButton-exit-active {
	animation-name: addSubButtonAnimation;
	animation-duration: 375ms;
	animation-direction: reverse;
}

.addSubButton-exit-done {
	opacity: 0;
}

@keyframes addSubButtonAnimation {
	0% {
		opacity: 0;
		transform: translateY(0);
	}

	100% {
		opacity: 1;
		transform: translateY(-65px);
	}
}
