@import '~styles/colors';

.communityRuleEditor {
	.title {
		font-weight: bold;
	}

	.editableRuleTitle {
		display: flex;
		flex-direction: row;
		margin-bottom: 0.25em;
	}

	.titleInput {
		flex-grow: 1;
		font-weight: bold;
	}

	.slateInput {
		background: transparent;
	}
}

.communityRuleEditor.editable {
	border: 1px dashed $darkGrey;
	padding: 3px;
	border-radius: 3px;
}
