@import '~styles/colors';

.adminUserLink {
	padding: 1px;
	border-radius: 0.3em;
	background-color: $adminBackgroundColor;
	color: $adminForegroundColor;
	font-weight: bold;
}

.adminUserLink:hover {
	color: $adminHoveredForegroundColor;
}
