@import '~styles/colors';
@import '~styles/sizes';

.resetPasswordForm {
	margin-top: 0.5em;

	.resetPasswordButton {
		margin-top: 0.25em;
	}
}

.resetPasswordForm label {
	font-weight: bold;
}

.resetPasswordError {
	color: $errorColor;
	font-weight: bold;
	margin-top: 0.5em;
}

.resetPasswordSuccess {
	color: $successColor;
	font-weight: bold;
	margin-top: 0.5em;
}

.resetPasswordFormField {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	margin-right: 5px;
	@media screen and (max-width: $break-medium) {
		width: 100%;
	}
}
