@import '~styles/colors.scss';
@import '~styles/sizes.scss';

.commentEditor {
	padding-bottom: 0.5em;
	.error {
		color: $errorColor;
	}
}

.commentEditor > .buttons > * {
	margin-right: 0.5em;
}

.postReplyButton {
	.actionCost {
		color: $monetaryActionColor;
		background-color: $backgroundColor;
		border-radius: 4px;
		margin-left: 4px;
		display: inline-block;
	}
	*:disabled {
		.actionCost {
			color: $darkGrey;
		}
	}
}

.moderationRefundInfo {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	font-size: $smaller-font-size;
	color: $textColorLight;
}
