@import '~styles/colors';

.standardButton {
	color: $primaryColor;
	font-weight: bold;
	border: none;
	border-radius: 5px;
	background-color: $lightBlue;
	padding: 0.15em;
	margin: 0;
}

.tinyButton {
	color: $primaryColor;
	font-weight: bold;
	border: none;
	border-radius: 5px;
	background-color: $lightBlue;
	padding: 0.1em;
}

.tinyButton:hover {
	background-color: $lighterBlue;
	color: $activeColor;
}

button.textButton {
	border: none;
	background: transparent;
	padding: 0;
	font-weight: inherit;
	color: $primaryColor;
}

button.textButton:hover {
	color: $activeColor;
}

button:disabled.textButton {
	color: $darkGrey;
	cursor: default;
}

.standardButton:hover {
	color: $activeColor;
	background-color: $lighterBlue;
}

.standardButton:disabled,
.standardButton:disabled:hover,
.tinyButton:disabled,
.tinyButton:disabled:hover {
	color: $darkGrey;
	background-color: rgba($darkGrey, 0.25);
	cursor: default;
}

@keyframes standardButtonPulse {
	0% {
		transform: scale(1);
		opacity: 0.75;
	}
	50% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0.75;
	}
}

.standardButton.pulsing,
.tinyButton.pulsing {
	-webkit-animation: standardButtonPulse 1s infinite ease-in-out;
	-o-animation: standardButtonPulse 1s infinite ease-in-out;
	-ms-animation: standardButtonPulse 1s infinite ease-in-out;
	-moz-animation: standardButtonPulse 1s infinite ease-in-out;
	animation: standardButtonPulse 1s infinite ease-in-out;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
