@import '~styles/colors';

.fileInputField.commonInput {
	border-style: none;
	background-color: rgba(101, 163, 255, 0.1);
	border-radius: 3px;
	white-space: nowrap;
	outline-width: 0;
	clear: right;
}

.fileInputField.commonInput:disabled {
	color: rgba(0, 0, 0, 0.5);
}

.fileInputField {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.filePreviewList {
	list-style: none;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-items: center;
	margin: 0;
	padding: 0;
	align-items: center;
}

.fileInputAdd {
	background-color: rgba(101, 163, 255, 0.2);
	color: white;
	height: 150px;
	width: 100px;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	cursor: pointer;
	&.validDragContents {
		cursor: copy;
	}
	&.invalidDragContents {
		cursor: no-drop;
	}
}
