@import '~styles/colors';
@import '~styles/sizes';

.formSubmitSuccess {
	color: $successColor;
	font-weight: bold;
	margin-top: 0.5em;
}

.formSubmitError {
	color: $errorColor;
	font-weight: bold;
	margin-top: 0.5em;
}

.accountChangePasswordField {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
}

.changePasswordFieldGrouping {
	padding-top: 1em;

	@media screen and (max-width: $break-medium) {
		padding-top: 0;
	}
}
