@import '~styles/sizes';
@import '~styles/colors';

.feedContainer {
	.actionCost {
		color: $monetaryActionColor;
		background-color: $backgroundColor;
		border-radius: 4px;
		padding: 1px 1px;
		margin-right: 1px;
		display: inline-block;
	}
	*:disabled {
		.actionCost {
			color: grey;
		}
	}
}

.feedContainerCard {
	.feed {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 0.75em;
		@media screen and (max-width: $break-medium) {
			padding-right: 0;
		}
	}
}

.feedContainerList {
	.feed {
		display: grid;
		grid-template-columns: fit-content(150px) auto;
		grid-row-gap: 1.25em;
		column-gap: 0.75em;
	}
}

.feedViewControls {
	margin-bottom: 0.75em;
}

.feedViewSettings {
	display: flex;

	.nWayToggle {
		padding-right: 0.5em;
	}
}

.feed.loading {
	opacity: 0.25;
}

.loadMoreContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 1em;

	.loadMoreButton {
		width: 200px;
	}
}

.nothingThere {
	text-align: center;
	font-weight: bold;
	width: 100%;
}
