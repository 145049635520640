@import '~styles/sizes';

.siteRules {
	max-width: 300px;

	@media screen and (max-width: $break-medium) {
		max-width: 100%;
		width: 100%;
	}
}
