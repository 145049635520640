@import '~styles/sizes';

.sendMessageFormField {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	display: block;
	margin-right: 5px;
	@media screen and (max-width: $break-medium) {
		width: 100%;
	}
}

.sendMessageSubjectField {
	width: 100%;
}

.sendMessageBody {
	width: 100%;
}
