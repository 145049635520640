$tipCountColor: rgb(61, 61, 61);
$monetaryActionColor: rgb(0, 87, 76);
$depositColor: rgb(1, 93, 221);
$monetaryActionDisabledColor: rgb(96, 121, 114);
$vestingColor: rgb(177, 77, 0);
$withdrawalColor: rgb(202, 5, 5);
$unreadMessagesColor: rgb(245, 128, 82);

$lighterBlue: rgb(237, 243, 255);
$lightBlue: rgb(227, 233, 255);
$mediumBlue: rgb(212, 219, 232);
$darkerBlue: rgb(193, 203, 219);

$primaryColor: rgb(42, 100, 150);
$activeColor: rgb(32, 76, 114);

$lightenedPrimaryColor: rgba(42, 100, 150, 0.33);

$successColor: darkolivegreen;
$errorColor: darkred;
$errorColorLight: #b84d4d;

$backgroundColor: white;

$lighterGrey: rgb(229, 229, 229);
$lightGrey: rgb(197, 197, 197);
$darkGrey: darkgrey;
$darkerGrey: #888888;

$offBackgroundColor: rgb(254, 254, 252);

$darkNeutral: rgb(60, 60, 60);

$textColor: black;
$textColorLight: #474747;
$textColorVeryLight: #989898;

$escrowBackgroundColor: antiquewhite;

$adminForegroundColor: white;
$adminHoveredForegroundColor: rgb(170, 202, 229);
$adminBackgroundColor: rgb(168, 48, 48);

$siteTagBackgroundColor: rgb(149, 42, 42);
$communityTagBackgroundColor: rgb(36, 123, 68);
$postTagBackgroundColor: rgb(37, 101, 127);

@if variable-exists('darkMode') {
	$tipCountColor: rgb(233, 233, 233);
	$monetaryActionColor: rgb(32, 179, 86);
	$vestingColor: rgb(239, 168, 105);
	$monetaryActionDisabledColor: rgb(128, 171, 159);
	$depositColor: rgb(93, 163, 252);
	$withdrawalColor: rgb(215, 92, 92);

	$lighterBlue: rgb(42, 55, 72);
	$lightBlue: rgb(50, 61, 67);
	$mediumBlue: rgb(127, 134, 155);
	$darkerBlue: rgb(116, 141, 183);

	$primaryColor: rgb(140, 188, 233);
	$activeColor: rgb(170, 202, 229);

	$lightenedPrimaryColor: rgba(42, 100, 150, 0.33);

	$successColor: #97d072;
	$errorColor: #e57171;
	$errorColorLight: #cba3a3;

	$backgroundColor: rgb(44, 44, 44);

	$lighterGrey: rgb(33, 33, 33);
	$lightGrey: rgb(44, 44, 44);
	$darkGrey: rgb(88, 88, 88);
	$darkerGrey: rgb(55, 55, 55);

	$offBackgroundColor: rgb(50, 50, 50);

	$darkNeutral: rgb(233, 233, 233);

	$textColor: rgb(210, 210, 210);
	$textColorLight: rgb(175, 175, 175);
	$textColorVeryLight: rgb(121, 121, 121);

	$escrowBackgroundColor: rgb(82, 77, 70);
}
