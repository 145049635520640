@import '~styles/colors';
@import '~styles/sizes';

.slateInput {
	min-height: 3em;
	width: 100%;
	border-style: none;
	border-radius: 3px;
}

.slateEditor {
	display: flex;
	flex-direction: column;

	background: $lighterBlue;
	width: 100%;
	max-width: 100%;
	border-radius: 3px;

	h2 {
		margin: 0.125em 0 0.25em 0;
	}

	ol {
		margin: 0.25em 0 0.25em 0;
	}

	ul {
		margin: 0.25em 0 0.25em 0;
	}

	a {
		text-decoration: underline;
	}

	.display {
		flex-grow: 1;
	}

	.editLinks {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		align-content: flex-end;
		align-items: center;
		font-size: $smallest-font-size;

		margin-right: 4px;

		> button {
			margin-left: 0.5em;
		}
	}
}

.slateEditor.displayOnly.editable {
	display: flex;
	align-items: flex-end;
	flex-direction: row;
	min-height: 1em;
}

.slateEditor.displayOnly.editable.withBorder {
	border: 1px dashed $darkGrey;
	padding: 3px;
}

.slateEditor.displayOnly {
	background: $backgroundColor;
	min-height: 0;
}

.slateToolbar {
	background-color: $lighterBlue;
	border-radius: 3px 3px 0 0;

	button {
		background-color: transparent;
	}

	.toolActive {
		color: $activeColor;
		background-color: $lightBlue;
	}

	button.standardButton.toolActive {
		border-radius: 0;
	}

	button.standardButton.toolActive:first-of-type {
		border-radius: 3px 0 0;
	}
}
