@import '~styles/colors';

.balanceTableSizingWrapper {
	flex-grow: 1;
}

.balanceTable {
	width: 100%;
	td {
		max-width: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.balanceSheetItemOdd {
	background-color: rgba($lightGrey, 0.125);
}

.balanceDeposit {
	color: $depositColor;
}

.balanceContentPayment {
	color: $monetaryActionColor;
}

.balanceWithdrawl {
	color: $withdrawalColor;
}

.balancePost {
	color: rgb(150, 57, 3);
}

.balanceComment {
	color: rgb(90, 3, 102);
}

.balancePlusMinus {
	width: 1em;
	text-align: center;
	display: inline-block;
}

.balanceCurrent {
	background-color: rgba($activeColor, 0.125);
	border-radius: 5px;
}

.balanceSheetItemChildHidden {
	display: none;
}

.balanceSheetItemExpanded {
	border-left: 1px solid lightgrey;
	border-right: 1px solid lightgrey;
	border-top: 1px solid lightgrey;
}

.balanceSheetItemDescription {
	color: $textColorLight;
}

.balanceSheetItemChild {
	color: $textColorLight;
	padding-bottom: 5px;
}

.balanceSheetNav {
	display: grid;
	grid-template-columns: 100px auto 100px;
	margin-top: 0.25em;
	text-align: center;
	width: 100%;
}

.balanceTableLoading {
	opacity: 0.7;
}

.balanceSheet {
	display: grid;
	grid-template-rows: 1fr;
	width: 100%;
	margin-top: 0.25em;
}

.navPreviousPage,
.navNextPage {
	button {
		height: 100%;
	}
	width: 20px;
	opacity: 0.75;
}
