// Responsive design mobile max-width threshold

$break-small: 400px;
$break-medium: 812px;
$max-site-width: 1280px;

// Standard font sizes
$smallest-font-size: 14px;
$smaller-font-size: 15px;
$small-font-size: 16px;
$medium-font-size: 17px;
$large-font-size: 18px;
$larger-font-size: 19x;
$largest-font-size: 21px;

// Doubles
$medium-font-size-2x: 32px;

// Matches the size of fa-lg
$fa-large-font-size: 20px;
