@import '~styles/sizes';
@import '~styles/safeAreaInsets';
@import '~styles/colors';

.tagPaletteSection {
	display: flex;
	flex-direction: column;
}

.tagPaletteSectionTitle {
	font-weight: bold;
	font-size: $large-font-size;
}

@keyframes slideInFromBottom {
	0% {
		transform: translateY(+100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes slideDown {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(+200%);
	}
}

.tagPalette {
	display: grid;
	grid-template-columns: 1fr;
	position: absolute;
	z-index: 1;
	background-color: rgba($backgroundColor, 0.95);
	padding: 0.25em 0.25em 1em;
	min-width: 300px;
	border-bottom-left-radius: 0.25em;
	border-bottom-right-radius: 0.25em;
	box-shadow: 0.01em 0.1em 0.5em 0.2em rgba($lighterGrey, 0.5);

	@media screen and (max-width: $break-medium) {
		animation: 0.25s ease-out 0s 1 slideInFromBottom;
		min-height: 15vh;
		max-height: 50vh;
		overflow-y: scroll;
		bottom: 0;
		left: 0;
		width: 100vw;
		position: fixed;
		padding-bottom: calc(1.5em + env(safe-area-inset-right));
		padding-top: 1em;
	}
}

.tagPalette.closing {
	@media screen and (max-width: $break-medium) {
		animation: 0.5s ease-out 0s 1 slideDown;
	}
}

.tagSectionTags {
	display: flex;

	> .tag {
		margin-right: 0.5em;
	}
}
