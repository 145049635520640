@import '~styles/colors';

.ruleSelect {
	width: 100%;
}

.communityRuleViolationReportFormButtons {
	width: 100%;
	margin-top: 0.25em;

	button:first-of-type {
		margin-right: 0.25em;
	}
}

.ruleSelectContainer {
	margin-bottom: 0.25em;
	margin-top: 0.5em;
}

.communityRuleViolationReportForm {
	.error {
		font-weight: bold;
		color: $errorColor;
		width: 100%;
		margin-top: 0.25em;
	}

	.reportSuccess {
		font-weight: bold;
		color: $successColor;
		margin-top: 0.25em;
	}
}
