@import '~styles/sizes';
@import '~styles/colors';

.feedItemThumbContainer {
	margin-bottom: 5px;
	box-shadow: 0.01em 0.1em 0.5em 0.2em rgba($lighterGrey, 0.5);
}

.feedItemThumb {
	width: 70px;
	display: inline-block;
	vertical-align: bottom;
}

.feedItemTitle {
	font-size: $medium-font-size;
	color: $darkNeutral;
	font-weight: bold;
	display: block;
}

.feedItemTitle:hover {
	text-decoration: none;
	background-color: rgba($lighterBlue, 0.65);
}

.feedItemValue {
	font-size: $small-font-size;
	text-align: center;
	width: 100%;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
	color: $tipCountColor;
	font-weight: bold;
}

.feedItemDetails {
	font-size: $smallest-font-size;
	color: $textColorLight;
	line-height: 100%;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
}

.feedItemRight > .feedItemLinks {
	> * {
		margin-right: 0.6em;
	}

	padding-top: 0.25em;
	font-size: $smaller-font-size;
	font-weight: bold;

	.feedItemTipLink {
		color: $monetaryActionColor;
		border-radius: 3px;
	}
}

.feedItemLinkDivider {
	width: 0.6em;
	display: inline-block;
}

.imageWrapper {
	position: relative;
}

.feedItemExpandoContainer {
	background-color: rgba(0, 0, 0, 0.35);
	position: absolute;
	right: 0;
	bottom: 0;
	box-sizing: border-box;
	width: 1em;
	height: 1em;
	max-width: 1em;
	max-height: 1em;
	display: flex;
	align-content: center;
	align-items: center;
}

.feedItemExpandoContainer:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.feedItemExpando {
	display: inline-block;
	box-sizing: border-box;
	width: 90%;
	height: 90%;
	max-width: 90%;
	max-height: 90%;
	background-image: url('/static/img/icons/expando_generic_white.svg');
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0.75;
	margin: auto;
}

.feedItemExpando:hover {
	opacity: 0.9;
	cursor: pointer;
}

.feedItemTipJar {
	vertical-align: top;
	text-align: center;
	width: 30px;
	height: 35.8px;
	background-size: cover;
	display: inline-block;
	background-image: url('/static/img/tip-jar-full.svg');
}

// Prevents blink/delay caused by image load on first hover
// TODO: Consider switching to a sprite
.feedItemTipJar:before {
	content: url('/static/img/tip-jar-full-hover.svg');
	width: 0;
	height: 0;
	visibility: hidden;
}

.feedItemTipJar:hover {
	cursor: pointer;
	background-image: url('/static/img/tip-jar-full-hover.svg');
}

.feedItemTipContainer {
	text-align: center;
	vertical-align: top;
	padding-right: 4px;
	padding-top: 5px;
	padding-bottom: 5px;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		opacity: 0.15;
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 0.45;
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 0.15;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
		opacity: 0.15;
	}
	50% {
		transform: scale(1);
		opacity: 0.45;
	}
	100% {
		transform: scale(1);
		opacity: 0.15;
	}
}

.feedItemThumbContainer.feedItemLoading,
.feedItemRight.feedItemLoading {
	-webkit-animation: pulse 1s infinite ease-in-out;
	-o-animation: pulse 1s infinite ease-in-out;
	-ms-animation: pulse 1s infinite ease-in-out;
	-moz-animation: pulse 1s infinite ease-in-out;
	animation: pulse 1s infinite ease-in-out;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.feedItemThumbPlaceholder {
	width: 70px;
	height: 50px;
	background-color: lightgray;
	opacity: 0.5;
	display: block;
}
