$safe-area-inset-top: 0;
$safe-area-inset-right: 0;
$safe-area-inset-bottom: 0;
$safe-area-inset-left: 0;

@mixin supports-safe-area-insets {
	@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
		$safe-area-inset-top: constant(safe-area-inset-top);
		$safe-area-inset-right: constant(safe-area-inset-right);
		$safe-area-inset-bottom: constant(safe-area-inset-bottom);
		$safe-area-inset-left: constant(safe-area-inset-left);
		@content;
	}
}
