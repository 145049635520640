@import '~styles/sizes';

.imageCarousel {
	display: flex;
	flex-direction: column;
	width: 100%;

	figure {
		margin: 0;
	}

	.navButton {
		height: 100%;
		opacity: 0.75;
		padding: 0;
	}

	.carouselNavigation {
		display: grid;
		text-align: center;
		grid-template-columns: 100px 7fr 100px;
		padding-top: 5px;
		padding-bottom: 5px;
	}
}

.carouselIndex {
	display: flex;
	width: 100%;
	justify-content: center;
	background-color: rgba(119, 119, 119, 0.25);
	font-size: $smaller-font-size;
	border-bottom-left-radius: 0.25em;
	border-bottom-right-radius: 0.25em;
}

.carouselImage {
	width: 100%;
	display: block;
}
