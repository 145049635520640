.messageWrapper {
	margin-top: 0.5em;
}

.userControls {
	margin-bottom: 0.5em;

	button {
		margin-right: 0.25em;
	}
}
