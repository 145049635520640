@import '~styles/colors';
@import '~styles/sizes';

.createPost {
	display: flex;
	flex-direction: column;
	flex-basis: content;
	max-width: 75%;

	.bodyInput {
		max-width: 500px;
	}
}

.newPostForm {
	.formLabel {
		font-size: $medium-font-size;
		font-weight: bold;
		display: inline-block;
		margin-bottom: 0.125em;
	}
}

.postTextEditor {
	background: $lightBlue;
	border-radius: 3px;
	width: 400px;
	max-width: 100%;
	min-height: 5em;
}

.postTitleEditor,
.postLinkEditor {
	width: 500px;
}

.newPostFormSection {
	padding-bottom: 0.5em;
	display: block;
	@media screen and (max-width: $break-medium) {
		width: 100%;
	}

	.nWayToggle {
		margin-bottom: -4px; // to remove margin added by inline-block XXX: do not like!
	}
}

.newPostFormSectionHeader {
	font-size: $large-font-size;
	margin-bottom: 1px;
}

.newPostFormSectionDescription {
	font-size: $small-font-size;
	color: darkgrey;
	margin-bottom: 2px;
}

.inputErrorLabel {
	color: $errorColor;
	font-weight: bold;
	padding-top: 0.5em;
}

.formError {
	> button {
		background-color: $errorColor !important;
		color: lightgrey !important;
	}
}

.newPostFormSubmit {
	display: inline-block;
	padding-right: 0.5em;

	.actionCost {
		color: $monetaryActionColor;
		background-color: $backgroundColor;
		border-radius: 4px;
		padding: 2px 4px;
		display: inline-block;
	}
	*:disabled {
		.actionCost {
			color: grey;
		}
	}
}

.newPostFormSaveDraft {
	display: inline-block;
}
