.collapsablePanel-enter {
	position: absolute;
	opacity: 0;
}
.collapsablePanel-enter-active {
	opacity: 1;
	position: var(--position);
	animation-name: collapsablePanelAnimation;
	animation-duration: var(--duration);
	animation-direction: normal;
	animation-timing-function: ease-in;
}
.collapsablePanel-enter-done {
	position: var(--position);
}
.collapsablePanel-exit-active {
	position: var(--position);
	animation-name: collapsablePanelAnimation;
	animation-duration: var(--duration);
	animation-direction: reverse;
	animation-timing-function: ease-in;
}
.collapsablePanel-exit-done {
	position: absolute;
}

@keyframes collapsablePanelAnimation {
	0% {
		height: 0;
		transform: scaleY(0);
		transform-origin: top;
	}
	100% {
		height: var(--panelHeight);
		transform: scaleY(1);
		transform-origin: top;
	}
}
