.loading {
  -webkit-animation: pulse 1s infinite ease-in-out;
  -o-animation: pulse 1s infinite ease-in-out;
  -ms-animation: pulse 1s infinite ease-in-out;
  -moz-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  pointer-events: none;
}