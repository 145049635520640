@import '~styles/sizes';

.userSidebarTitle {
	font-weight: bold;
	font-size: $medium-font-size;
	padding-top: 5px;
	margin-bottom: 4px;
}

.userSidebar {
	margin-bottom: 1em;
}

.userSidebarInner {
	margin-bottom: 1em;
}
